import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./footer.css"; // Ensure this is the correct path to your CSS file

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [showContactModal, setShowContactModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleContactModal = () => setShowContactModal(!showContactModal);

  const handleLinkClick = (path) => {
    navigate(path);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <footer className="footer-bg mt-5 py-4">
      <div className="container">
        <div className="row text-center text-md-left">
          <div className="col-md-3 mb-4 mb-md-0">
            <h4 className="footer-title">Quick Links</h4>
            <ul className="list-unstyled">
              <li>
                <Link to="/" onClick={() => handleLinkClick('/')} className="footer-link">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/About" onClick={() => handleLinkClick('/')} className="footer-link">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/services" onClick={() => handleLinkClick('/')} className="footer-link">
                  Services
                </Link>
              </li>
              <li>
                <Link to="/CarsPage" onClick={() => handleLinkClick('/')} className="footer-link">
                  Cars
                </Link>
              </li>
              <li>
                <Link to="/Utes" onClick={() => handleLinkClick('/')} className="footer-link">
                  Trucks & Utes
                </Link>
              </li>
              <li>
                <Link to="/PrivacyPolicy" onClick={() => handleLinkClick('/')} className="footer-link">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/Termservice" onClick={() => handleLinkClick('/')} className="footer-link">
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link to="/Faqs" onClick={() => handleLinkClick('/')} className="footer-link">
                  FAQs
                </Link>
              </li>
              <li>
                <a href="#" onClick={toggleContactModal} className="footer-link">
                  Contact
                </a>
              </li>
            </ul>
          </div>

          <div className="col-md-6 mb-4 mb-md-0">
            <h4 className="footer-title">Follow Us</h4>
            <div className="social-links d-flex justify-content-center">
              <a
                href="https://www.instagram.com/tak8_australia/"
                target="_blank"
                className="footer-link me-3"
                rel="noreferrer"
                aria-label="Instagram"
              >
                <i className="bi bi-instagram"></i>
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61558749323910"
                target="_blank"
                className="footer-link ms-5"
                rel="noreferrer"
                aria-label="Facebook"
              >
                <i className="bi bi-facebook"></i>
              </a>
            </div>
            <div className="social-names d-flex justify-content-center mt-2">
              <span className="me-4">Instagram</span>
              <span>Facebook</span>
            </div>
          </div>

          <div className="col-md-3">
            <h4 className="footer-title">Head Office Address</h4>
            <div className="footer-contact">
              <div>
                <i className="ri-home-fill"></i>
                <a
                  href="https://www.google.com/maps/search/?api=1&query=17+Regal+Place+East+Perth"
                  target="_blank"
                  className="footer-link"
                  rel="noreferrer"
                >
                  <strong>17 Regal Place East Perth</strong>
                </a>
              </div>
            </div>
            <h4 className="footer-title mt-4">Customer Support</h4>
            <ul className="list-unstyled">
              <li>
                <i className="ri-phone-fill me-1"></i>
                <a href="tel:+61893415334" className="footer-link">
                  <strong>+61893415334</strong>
                </a>
              </li>
              <li>
                <i className="ri-mail-fill me-1"></i>
                <a href="mailto:info@tak8.com.au" className="footer-link">
                  <strong>info@tak8.com.au</strong>
                </a>
              </li>
              <li>
                <i className="ri-time-fill me-1"></i>
                <span>Mon - Fri: 9:00 AM - 5:00 PM</span>
              </li>
            </ul>
          </div>

          <div className="col-md-3">
            <h4 className="footer-title">Latest News</h4>
            <ul className="list-unstyled">
              <li>
                <Link to="/news/NewFleet" onClick={() => handleLinkClick('/news/1')} className="footer-link">
                  New fleet of cars available now!
                </Link>
              </li>
              <li>
                <Link to="/news/SpecialOffers" onClick={() => handleLinkClick('/news/2')} className="footer-link">
                  Special Offers 
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="text-center mt-3">
          <p>&copy; {currentYear} Tak8. All Rights Reserved.</p>
        </div>
      </div>

      <Modal show={showContactModal} onHide={toggleContactModal}>
        <Modal.Header closeButton>
          <Modal.Title>Contact Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <i className="ri-cellphone-fill"></i>
            <a href="tel:+61409521554" className="footer-link">
              <strong>+61409521554</strong>
            </a>
          </div>
          <div>
            <i className="ri-phone-fill"></i>
            <a href="tel:+61893415334" className="footer-link">
              <strong>+61893415334</strong>
            </a>
          </div>
          <div>
            <i className="ri-mail-fill"></i>
            <a href="mailto:info@tak8.com.au" className="footer-link">
              <strong>info@tak8.com.au</strong>
            </a>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleContactModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </footer>
  );
};

export default Footer;
