import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaCalculator, FaPhone, FaMapMarkerAlt, FaBars } from "react-icons/fa";
import './header.css';

const Header = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 920);
  const location = useLocation();

  const updateScreenSize = useCallback(() => {
    setIsSmallScreen(window.innerWidth < 920);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateScreenSize);
    return () => window.removeEventListener('resize', updateScreenSize);
  }, [updateScreenSize]);

  useEffect(() => {
    if (isSmallScreen) {
      const navbarCollapse = document.getElementById('navbarSupportedContent');
      if (navbarCollapse && navbarCollapse.classList.contains('show')) {
        navbarCollapse.classList.remove('show');
      }
    }
  }, [location, isSmallScreen]);

  const handleNavLinkClick = () => {
    const navbarCollapse = document.getElementById('navbarSupportedContent');
    if (navbarCollapse) {
      navbarCollapse.classList.remove('show');
    }
  };

  return (
    <header className="header">
      <div className="header_top">
        <div className="container-fluid p-2">
          <div className="d-flex justify-content-between align-items-center px-3">
            <Link to="/">
              <img
                src={`${process.env.PUBLIC_URL}/Tak8Logo.webp`}
                alt="Logo"
                className="logo"
                width="220"
                height="auto"
                loading="lazy"
              />
            </Link>
            {!isSmallScreen && (
              <Link to="QuoteForm" className="quote_section d-flex align-items-center text-dark">
                <FaCalculator className="me-2 fs-4" />
                <h4 className='m-0'>Get Customized Quotation</h4>
              </Link>
            )}
            {!isSmallScreen && (
              <div className='contact-info text-center text-dark'>
                <a href="tel:+61409521554" className="text-decoration-none  fw-bolder mt-2">
                  <FaPhone className="" /><h4>+61409521554</h4>
                </a>
                <a href="https://www.google.com/maps/search/?api=1&query=17+Regal+Place+East+Perth" target="_blank" rel="noopener noreferrer" className="d-block text-decoration-none fs-5 fw-bolder">
                  <FaMapMarkerAlt className=" mt-2" /><h4>17 Regal Place East Perth</h4>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mid-header">
        <div className="ticker-container">
          <div className="ticker-content">
            <span className="me-3">
              <i className="fas fa-clock me-2"></i> Perth Airport Vehicle Pickup and Drop-Off Service
            </span>
            <span>
              <i className="fas fa-phone-alt me-2"></i> Including After-Hours Available!
            </span>
          </div>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg header-pages fw-bold">
        <div className="container-fluid">
          <button className="navbar-toggler bg-dark" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <FaBars className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link" to="/" onClick={handleNavLinkClick}>Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/CarsPage" onClick={handleNavLinkClick}>Cars</Link>
              </li>
                <li className="nav-item">
                <Link className="nav-link" to="/Utes" onClick={handleNavLinkClick}>Trucks & Utes</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/About" onClick={handleNavLinkClick}>About Us</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/Services" onClick={handleNavLinkClick}>Tak8 Services</Link>
              </li>
            
              {isSmallScreen && (
                <>
                  <Link to="QuoteForm" className="quote_section text-dark text-center" onClick={handleNavLinkClick}>
                    <FaCalculator className="me-2 fs-4" />
                    <h4 className='m-0'>Get Customized Quotation</h4>
                  </Link>
                  <div className='contact-info text-center text-dark'>
                    <a href="tel:+61409521554" className="d-block text-decoration-none fs-5 fw-bolder">
                      <FaPhone className="me-2" /><h4>+61409521554</h4>
                    </a>
                    <a href="https://www.google.com/maps/search/?api=1&query=17+Regal+Place+East+Perth" target="_blank" rel="noopener noreferrer" className="d-block text-decoration-none fs-5 fw-bolder">
                      <FaMapMarkerAlt className="me-2" /><h4>17 Regal Place East Perth</h4>
                    </a>
                  </div>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
