import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, InputGroup, FormControl, ListGroup } from 'react-bootstrap';
import io from 'socket.io-client';
import './ChatModal.css';

const socket = io('http://localhost:4000'); // Ensure this points to your server

const ChatModal = ({ show, handleClose, isAdmin }) => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const userId = isAdmin ? 'admin' : Date.now().toString(); // Unique ID for the user or admin

  useEffect(() => {
    socket.emit(isAdmin ? 'admin' : 'user');
    console.log(isAdmin ? 'Admin connected' : 'User connected');

    socket.on('connect', () => {
      console.log('Connected to server');
    });

    socket.on('receiveMessage', (msgs) => {
      console.log('Messages received:', msgs); // Log received messages
      setMessages(msgs);
    });

    socket.on('messageRead', (msgId) => {
      console.log('Message read:', msgId); // Log message read events
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === msgId ? { ...msg, read: true } : msg
        )
      );
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    return () => {
      socket.disconnect();
    };
  }, [isAdmin]);

  const sendMessage = (e) => {
    e.preventDefault();
    const newMessage = { id: Date.now(), userId, text: message, read: false };
    console.log('Sending message:', newMessage); // Log messages being sent
    socket.emit('sendMessage', newMessage);
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setMessage('');
  };

  const handleSeenMessage = (msgId) => {
    console.log('Marking message as read:', msgId); // Log message read events
    socket.emit('readMessage', msgId);
  };

  return (
    <Modal show={show} onHide={handleClose} dialogClassName="chat-modal">
      <Modal.Header closeButton>
        <Modal.Title>Chat</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="chat-container">
          <ListGroup className="messages-list">
            {messages.map((msg) => (
              <ListGroup.Item
                key={msg.id}
                className={`message ${msg.userId === userId ? 'sent' : 'received'} ${msg.read ? 'read' : ''}`}
                onClick={() => handleSeenMessage(msg.id)}
              >
                {msg.text}
              </ListGroup.Item>
            ))}
          </ListGroup>
          <Form onSubmit={sendMessage} className="message-form">
            <InputGroup>
              <FormControl
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type a message"
              />
              <Button type="submit" variant="primary">Send</Button>
            </InputGroup>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChatModal;
